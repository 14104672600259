import './App.css';
import video from '../src/video/video.mov'
import { Fragment } from 'react';
import Hero from './components/Hero';

function App() {

  return (
    <Fragment>
      <div  className='loadingbg-video'>
        <video autoPlay src={video} loop muted >
        </video>
      </div>
      <Hero
      />
    </Fragment>
  );
}

export default App;
