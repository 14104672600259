import { Button, Center, ChakraProvider, Heading, HStack, VStack, Text, ButtonGroup, Image } from "@chakra-ui/react";
import { motion } from "framer-motion";
import logo from '../video/logo-solo.png';




function Hero() {

    return (
        <Center h='800px' className="loadinghero">
            
            <VStack >
            <motion.div
                        initial={{
                            opacity: 0,
                            y: -20,
                        }}
                        animate=
                        {{ opacity: 1, y:0, }}
                        transition={{
                            delay: 1,
                            duration: 0.5,
                            ease: "easeIn",
                        }}
                        exit={{y: 0}}
                    >
            <Image src={logo} w='20'></Image>
            </motion.div>
                <Heading
                    lineHeight={1.1}
                    fontWeight={600}
                    fontSize={{ base: '2xl', sm: '4xl', lg: '4xl' }}
                    textAlign='center'
                    justify={'center'}
                    
                    >
                    <motion.div
                        initial={{
                            opacity: 0
                        }}
                        animate=
                        {{ scale: [1.25, 1], opacity: 1 }}
                        transition={{
                            delay: 2,
                            duration: 1.25,
                            ease: "easeInOut",
                        }}
                    >
                        <Text
                            as={'span'}
                            position={'relative'}
                            color='white'
                            textShadow='2px 2px rgba(0, 0, 0, 0.500);'>
                            Segurança, tecnologia
                        </Text>
                        <br />
                        <Text as={'span'} color={'white'}
                            paddingRight={2}
                            textShadow='2px 2px rgba(0, 0, 0, 0.500);'
                            >
                            &
                        </Text>
                        <Text as={'span'} color='white' textShadow='2px 2px rgba(0, 0, 0, 0.500);'>
                            confiabilidade
                        </Text>
                        
                    </motion.div>
                </Heading>
                <HStack paddingTop='5px'>
                <motion.div
                        initial={{
                            opacity: 0
                        }}
                        animate=
                        {{ scale: [1.25, 1], opacity: 1 }}
                        transition={{
                            delay: 5,
                            duration: 1.25,
                            ease: "easeInOut",
                        }}
                    >
                         
                        <ButtonGroup>
                          
                            <motion.div 
                             animate=
                             {{ scale: [1.05, 1]}}
                             transition={{
                                 duration: 1,
                                 delay: 2,
                                 ease: "easeIn",
                                 repeat: Infinity,
                                 repeatDelay: 0.5,
                                 repeatType: 'loop'
                             }}
                            
                            >
                           <Button colorScheme={'blue'} color='white' variant='solid' href='/interas'>
                            Conheça a Interas
                            </Button>
                            </motion.div>
                            
                            
                        </ButtonGroup>

                        </motion.div>
                        </HStack>
            </VStack>
        </Center>
    );
}
export default Hero;